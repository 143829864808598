.ag-theme-alpine {
    --ag-header-column-separator-display: block;    
    --ag-cell-horizontal-border: var(--ag-row-border-style) var(--ag-row-border-color) var(--ag-row-border-width);
}

/* selectively replace the group icon with one from Material Design Icons */
.ag-theme-alpine .ag-icon-group {
    --ag-icon-font-family: "Material Design Icons";
    --ag-icon-font-code-group: "\F0328";
    --ag-icon-font-code-copy: "\F0328"
}

.ag-theme-alpine, .ag-theme-alpine-dark {
    --ag-icon-font-family: agGridMaterial;
}

/* fade text to grey */
.ag-grid-custom-disabled-text-color {
    color: grey;
}

/* fade empty cell to grey. Used by pricing grid*/
.ag-grid-custom-black-out-cell-color {
    background-color: var(--ag-header-background-color);
}

.ag-grid-pricing-row-span-label {
    background-color: var(--ag-header-background-color);
    z-index: 100 !important;
    height: unset !important;
    text-orientation: mixed;
    text-overflow: ellipsis;
    white-space: nowrap; 
}

.ag-grid-pricing-row-span-label-vertical {
    padding-top: 10px !important;
    writing-mode: vertical-lr;
}

.ag-grid-pricing-row-span-label-horizontal {
    text-align: center;
}

.ag-grid-index-column {
    background-color: var(--ag-header-background-color);
    justify-content: center;
    text-align: center;
}

/* Couple of CSS hacks to enable/disable row drag based on whether the row is selected first */
.ag-grid-index-column .ag-row-drag {
    position: absolute;
    z-index: 2;
}
.ag-grid-index-column .ag-row-drag .ag-icon::before {
    content: "";
}
.ag-row-selected > .ag-grid-index-column .ag-row-drag {
    width: 100px;
}

.ag-grid-disable-status-bar .ag-status-bar {
    height: 0px;
}

.ag-cell {
    text-decoration: inherit;
}

.ag-grid-dropdown-cell-button {
    visibility: hidden;
}

.ag-row-hover > .ag-cell > .ag-grid-dropdown-cell-button {
    visibility: visible;
}
.ag-row-selected > .ag-cell > .ag-grid-dropdown-cell-button {
    visibility: visible;
}
.ag-cell-focus > .ag-grid-dropdown-cell-button {
    visibility: visible;
}

/* Disables the minimum height on the grid. Needed for the pricing grid */
.ag-center-cols-viewport {
    min-height: unset !important;
}
.ag-layout-print .ag-center-cols-container {
    min-height: unset !important;
}

/* Used for header borders on the pricing grid */
.ag-theme-alpine .ag-header-span-height  {
    --ag-header-column-separator-display: none;    
}